/*
 * @Author: chenggui 1028329845@qq.com
 * @Date: 2022-11-19 14:06:46
 * @LastEditors: chenggui 1028329845@qq.com
 * @LastEditTime: 2022-11-20 10:59:08
 * @FilePath: \www\src\utils\Base3d.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import * as THREE from 'three' // 导入整个 three.js核心库
import { EquirectangularReflectionMapping } from 'three' // 导入纹理映射模块
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader' // 导入RGB加载器
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls' // 导入控制器模块，轨道控制器
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader' // 导入GLTF模块，模型解析器,根据文件格式来定

class Base3d {
  constructor(selector) {
    this.container = document.querySelector(selector)
    this.camera
    this.scene
    this.renderer
    this.controls
    this.init()
    this.animate()
  }
  init () {
    // 初始化场景
    this.initScene()
    // 三维辅助线
    this.axesHelper()
    this.initLight()
    // 初始化相机
    this.initCamera()
    // 初始化渲染器
    this.initRender()
    // 初始化控制器，控制摄像头,控制器一定要在渲染器后
    this.initControls()
    // 添加物体模型
    this.addMesh()
    // 监听场景大小改变，跳转渲染尺寸
    window.addEventListener('resize', this.onWindowResize.bind(this))
  }
  initScene () {
    this.scene = new THREE.Scene()
    this.setEnvMap('002')
  }
  // 光源
  initLight () {
    const ambientLight = new THREE.AmbientLight(0xffffff, 1)
    // 右下角点光源
    const light_rightBottom = new THREE.PointLight(0x0655fd, 5, 0)
    light_rightBottom.position.set(0, 0, 0)
    this.scene.add(light_rightBottom)
    this.scene.add(ambientLight)
  }
  initCamera () {
    const width = window.innerWidth // 窗口宽度
    const height = window.innerHeight // 窗口高度
    const k = width / height // 窗口宽高比
    const s = 200 // 三维场景显示范围控制系数，系数越大，显示的范围越大

    this.camera = new THREE.OrthographicCamera(-s * k, s * k, s, -s, 1, 1000)
    this.camera.position.set(200, 300, 200) // 设置相机位置
    this.camera.lookAt(this.scene.position) // 设置相机方向(指向的场景对象)

    // this.camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.25, 200)
    // this.camera.position.set(-1.8, 0.6, 20)
  }
  initRender () {
    this.renderer = new THREE.WebGLRenderer({ antialias: true }) // 设置抗锯齿
    // 设置屏幕像素比
    this.renderer.setPixelRatio(window.devicePixelRatio)
    // 渲染的尺寸大小
    this.renderer.setSize(window.innerWidth, window.innerHeight)
    // 色调映射
    this.renderer.toneMapping = THREE.ACESFilmicToneMapping
    // 曝光
    this.renderer.toneMappingExposure = 3

    // this.renderer.setClearColor(0xb9d3ff, 1) // 设置背景颜色

    this.container.appendChild(this.renderer.domElement)
  }
  setEnvMap (hdr) { // 设置环境背景
    new RGBELoader().setPath('others/hdr/').load(hdr + '.hdr', (texture) => {
      texture.mapping = EquirectangularReflectionMapping // 圆柱形形纹理映射
      this.scene.background = texture
      this.scene.environment = texture
    })
  }
  axesHelper () { // 三维辅助线
    const axisHelper = new THREE.AxesHelper(250)
    this.scene.add(axisHelper)
  }
  render () {
    this.renderer.render(this.scene, this.camera)
  }
  animate () {
    this.renderer.setAnimationLoop(this.render.bind(this))
  }
  initControls () {
    this.controls = new OrbitControls(this.camera, this.renderer.domElement)
  }
  // 加载模型
  setModel (modelName) {
    const material = new THREE.MeshPhongMaterial()
    material.map = new THREE.TextureLoader().load(require('@/assets/images/earth_bg.png'))
    material.blendDstAlpha = 1
    // 几何体
    this.sphereGeometry = new THREE.SphereGeometry(50, 64, 32)
    // 模型
    this.sphere = new THREE.Mesh(this.sphereGeometry, material)
    this.scene.add(this.sphere)

    // return new Promise((resolve, reject) => {
    //     const loader = new GLTFLoader().setPath('others/hdr/')
    //     loader.load(modelName, (gltf) => {
    //         console.log(gltf)
    //         this.model = gltf.scene.children[0]
    //         this.scene.add(this.model)
    //         // resolve(this.modelName + '模型添加成功')
    //     })
    // })
  }
  addMesh () {
    this.setModel('111.gltf')
  }
  onWindowResize () { // 调整屏幕大小
    this.camera.aspect = window.innerWidth / window.innerHeight // 摄像机宽高比例
    this.camera.updateProjectionMatrix() // 相机更新矩阵，将3d内容投射到2d面上转换
    this.renderer.setSize(window.innerWidth, window.innerHeight)
  }
}
export default Base3d
