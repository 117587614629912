<template>
    <div id="scene" class="scene" />
</template>

<script>
import Base3d from '../utils/Base3d'
import { reactive, onMounted } from 'vue'

export default {
    name: 'Test',
    data() {
        return {
            base3d: {}
        }
    },
    mounted() {
        this.base3d = new Base3d('#scene')
    },
    methods: {
    }
}

// const data = reactive({
//     base3d: {}
// })
// onMounted(() => {
//     data.base3d = new Base3d('#scene')
// })
</script>

<style>
*{
    margin:0;
    padding: 0;
}
</style>
